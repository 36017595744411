import React, { useEffect, useState } from "react"
import { useIntl } from "gatsby-plugin-intl"
import {
  StyledDescription,
  StyledImage,
  StyledHeader,
  StyledStoryBox,
  StyledText,
  StyledTitle,
  StyledOverlay,
  StyledWrapper,
  StyledMobileTextWrapper,
  StyledBenefits,
  StyledNgoList,
  StyledNgoListItem,
  StyledNgoListTitle,
  StyledHowToJoin,
  StyledHowToJoinImage,
  StyledNgoBullets,
  StyledNgoText,
  StyledLogoSlice,
  StyledSliceHeader,
  StyledOnlinePageTitle,
  StyledOnlinePageHeader,
} from "./index.styles"
import { LazyImage, Spinner } from "@tmu/components/common"
import { Benefits } from "@tmu/components/digital-banking"
import { getValueForLocale } from "@tmu/utils/string"
import { HowItWorksForm } from "@tmu/components/how-it-works"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"

const { CLOUDFLARE_IMAGE_URL } = process.env

const JoinPageComponent = ({ pageData, carrot, blue }) => {
  const { locale, defaultLocale } = useIntl()
  const [firstLoad, setFirstLoad] = useState(false)
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const imageHeight = isWide ? 800 : isDesktop ? 500 : isTablet ? 420 : 350
  const howToJoinHeight = isWide ? 600 : isDesktop ? 360 : isTablet ? 320 : 260
  const logoWidth = isWide ? 413 : isDesktop ? 223 : isTablet ? 168 : 100
  const numberOfNgos = 8

  const textData = (
    <>
      <StyledOnlinePageTitle carrot={carrot} blue={blue}>
        {pageData?.sliceBannerData?.title}
      </StyledOnlinePageTitle>
      <StyledDescription>
        {pageData?.sliceBannerData?.description}
      </StyledDescription>
      {pageData?.sliceBannerData?.description2 && (
        <StyledDescription>
          {pageData?.sliceBannerData?.description2}
        </StyledDescription>
      )}
      {pageData?.sliceBannerData?.description3 && (
        <StyledDescription>
          {pageData?.sliceBannerData?.description3}
        </StyledDescription>
      )}
      {pageData?.sliceBannerData?.description4 && (
        <StyledDescription>
          {pageData?.sliceBannerData?.description4}
        </StyledDescription>
      )}
    </>
  )

  const contactFormTitle = <h3>{pageData?.contactFormTitle}</h3>
  const contactFormDescription = <p>{pageData?.contactFormDescription}</p>

  useEffect(() => {
    setFirstLoad(true)
  }, [])

  if (!firstLoad) {
    return (
      <>
        <Spinner />
      </>
    )
  }

  return (
    <StyledWrapper>
      <div>
        <StyledStoryBox carrot={carrot} blue={blue}>
          <StyledText>
            <StyledOnlinePageHeader carrot={carrot} blue={blue}>
              {pageData?.sliceBannerData?.header}
            </StyledOnlinePageHeader>
            {isTablet && textData}
          </StyledText>
          {!isTablet && <StyledOverlay></StyledOverlay>}
          <StyledImage>
            <LazyImage
              altName="join as non-profit"
              src={pageData?.sliceBannerData?.image}
              width="auto"
              height={imageHeight}
              fit="cover"
            />
          </StyledImage>
        </StyledStoryBox>
        {!isTablet && (
          <StyledMobileTextWrapper>{textData}</StyledMobileTextWrapper>
        )}
      </div>
      <StyledBenefits>
        <div className="benefits-module">
          <Benefits
            carrot={carrot}
            blue={blue}
            title={pageData?.benefitsTitle}
            benefits={pageData?.benefitCards}
          />
        </div>
      </StyledBenefits>
      <StyledLogoSlice>
        <StyledNgoListTitle>{pageData?.logoItemsTitle}</StyledNgoListTitle>
        <StyledNgoList sender={pageData?.sender}>
          {pageData?.logoItems?.slice(0, numberOfNgos)?.map((item, index) => {
            return (
              <StyledNgoListItem key={index}>
                <LazyImage
                  altName={"logo-" + index}
                  src={getValueForLocale(
                    item?.node,
                    "logo",
                    locale,
                    defaultLocale
                  )}
                  width="auto"
                  height={logoWidth}
                  fit="contain"
                />
              </StyledNgoListItem>
            )
          })}
        </StyledNgoList>
      </StyledLogoSlice>
      <StyledHowToJoin>
        <StyledStoryBox small>
          <StyledNgoText>
            <StyledSliceHeader>
              {pageData?.howToJoinItemsTitle}
            </StyledSliceHeader>
            <StyledNgoBullets>
              <ul>
                {pageData?.howToJoinItems?.map((item, index) => {
                  return <li key={index}>{item}</li>
                })}
              </ul>
            </StyledNgoBullets>
            <p>{pageData?.howToJoinItemsAdd}</p>
          </StyledNgoText>
          <StyledHowToJoinImage>
            <LazyImage
              altName="join as non-profit"
              src={
                CLOUDFLARE_IMAGE_URL +
                "/static/assets/images/join/hot-air-balloon.png"
              }
              width="auto"
              height={howToJoinHeight}
              fit="cover"
              trim={!isTablet ? "0;0;0;700" : ""}
            />
          </StyledHowToJoinImage>
        </StyledStoryBox>
      </StyledHowToJoin>
      <div className="form-style">
        <HowItWorksForm
          title={contactFormTitle}
          desc={contactFormDescription}
          termsLink={pageData?.termsLink}
          sender={pageData?.sender}
          isWebsite={true}
        />
      </div>
    </StyledWrapper>
  )
}
export default JoinPageComponent
